import React from 'react';
import { Input, Button, Table } from 'semantic-ui-react';

type ScheduleFormProps = {
  programName: string;
  weekBeginDate: string;
  submitting: boolean;
  handleSubmit: () => void;
  handleProgramNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

function ScheduleForm({
  programName,
  weekBeginDate,
  submitting,
  handleSubmit,
  handleProgramNameChange,
  handleDateChange,
}: ScheduleFormProps) {
  return (
    <Table columns={3} stackable>
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <Input
              placeholder="Audience Name Goes Here"
              value={programName}
              size="large"
              fluid
              onChange={handleProgramNameChange}
            />
          </Table.Cell>
          <Table.Cell>
            <Input
              type="date"
              title='Week Day'
              value={weekBeginDate}
              size="large"
              onChange={handleDateChange}
            />
          </Table.Cell>
          <Table.Cell>
            <Button
              primary
              floated="right"
              type="submit"
              size="large"
              loading={submitting}
              disabled={submitting}
              icon="podcast"
              content="Generate Schedule Package"
              onClick={handleSubmit}
            />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}

export default ScheduleForm;
