import { useState } from "react";
import { toast } from "react-toastify";
import { Dimmer, Grid, Label, Loader, Modal, Segment, Table } from "semantic-ui-react";
import { useAuth } from "../../app/context/auth_provider";
import { Result } from "../../models/common/paged_result";
import { CourseAllModuleData, StudentCourseModuleProgressReport } from "../../models/report/report";
import { api } from "../../shared/axios_wrapper";
import DownloadCSV from "../../app/components/download_report";
import { formatDate } from "../../shared/utils";
import { UserRole } from "../../models/user/user";

interface CourseAllModuleProgressProps {
    moduleRptInput: CourseAllModuleData | undefined,
    showModal: boolean,
    setShowModal: (val: boolean) => void;
}

export function CourseAllModuleProgress({moduleRptInput, showModal, setShowModal }: CourseAllModuleProgressProps) {
    const { user } = useAuth();
    const [moduleRptLoading, setModuleRptLoading] = useState(true);
    const [courseAllModuleReport, setCourseAllModuleReport] = useState<StudentCourseModuleProgressReport[]>();

    const downloadModuleParams = {
        accountId: user?.accountId,
        locationId: moduleRptInput?.locationId,
        userId: moduleRptInput?.userId,
        courseId: moduleRptInput?.courseId,
        pageIndex: 0,
        pageSize: 500000,
        download: true
    };

    const getDownloadModuleRptUrl = () => { return `report/student-course-all-module-progress-report`; }

    const getCourseAllModuleReport  = () => {
        if ((user?.accountId ?? 0) > 0) {
            const url = `report/student-course-all-module-progress-report`;
            var params = {
                accountId: user?.accountId ?? 0,
                locationId: moduleRptInput?.locationId,
                userId: moduleRptInput?.userId,
                courseId: moduleRptInput?.courseId
            };
            //console.log('input:');
            //console.log(params);
            api.getWithInput<Result<StudentCourseModuleProgressReport[]>>(url, params)
                .then((r) => {
                    if (r.data?.result) {
                        setCourseAllModuleReport(r.data.result);
                        //console.log(r.data?.result);
                    }
                })
                .catch((e) => { toast.error("Error", { theme: "colored" }) })
                .finally(() => setModuleRptLoading(false));
        }
    }

    const getDownloadModuleFileName = () => {
        return "user_course_all_module_progress_data.csv";
    }

    const FilterCourseProgress = () => {
        return (
            <Grid stackable container>
                <Grid.Row>
                    <Grid.Column width={12} verticalAlign='middle'>
                        Account Name: {user?.accountName} <br />
                        Location Name: {moduleRptInput?.locationName} <br />
                        Person Name: {moduleRptInput?.userName} <br />
                        Course Name: {moduleRptInput?.courseName} <br />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    const CourseAllModuleReport = () => {
        return (
            <>
                <br />
                {(courseAllModuleReport?.length ?? 0) > 0 && <DownloadCSV
                    url={getDownloadModuleRptUrl()}
                    params={downloadModuleParams}
                    fileName={getDownloadModuleFileName()}
                />}
                <div style={{ "paddingTop": "4px", "paddingBottom": "4px" }}>
                    Module Count: {courseAllModuleReport?.length}&nbsp;
                    Completed: {courseAllModuleReport?.filter(x => x.completedFlag === true).length}
                </div>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Module Name</Table.HeaderCell>
                            <Table.HeaderCell>Accessed Dates</Table.HeaderCell>
                            <Table.HeaderCell>Quiz Attempts</Table.HeaderCell>
                            <Table.HeaderCell>Completed?</Table.HeaderCell>
                            {user?.role === UserRole.SuperAdmin && <Table.HeaderCell>Video Statistics</Table.HeaderCell>}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {courseAllModuleReport?.map((s, index) => (
                            <Table.Row key={"l" + s.locationId + "_s_" + s.userId + '_c_' + s.courseId + '_m_' + s.moduleId}>
                                <Table.Cell>
                                    {(s.completedFlag === true) && <div><Label ribbon color="green">{"Completed"}</Label></div>}
                                   {s.moduleName}
                                </Table.Cell>
                                <Table.Cell>
                                    First Accessed Date: <br />
                                    {formatDate(s.firstModuleAccessedDate, true)} <br />

                                    Last Accessed Date: <br />
                                    {formatDate(s.lastModuleAccessedDate, true)} <br />
                                </Table.Cell>
                                <Table.Cell>
                                    Last Quiz Date: <br/>{formatDate(s.lastQuizAttemptedDate, true)} <br />
                                    Last Grade: {s.lastQuizGrade?.toFixed(0)}% <br />
                                    Max Grade: {s.maxQuizGrade?.toFixed(0)}% <br/>
                                    Total Attempts: {s.totalQuizAttempts}
                                </Table.Cell>
                                <Table.Cell>
                                    Completed: {(s.completedFlag ?? false) ? "Yes" : "No"} <br />
                                    Completed Date: <br/> {formatDate(s.completedDate, true)}
                                </Table.Cell>
                                {user?.role === UserRole.SuperAdmin &&
                                <Table.Cell textAlign="center">
                                     Video 1 View Time (min): {s.video1ViewTimeInMin} <br/>
                                     Video 2 View Time (min): {s.video2ViewTimeInMin}
                                </Table.Cell>
                                }
                            </Table.Row>
                        ))}
                    </Table.Body>
                    <Table.Footer fullWidth>
                        <Table.Row>
                            <Table.HeaderCell colSpan={10} textAlign="right">
                                {(courseAllModuleReport?.length ?? 0) > 0 && <DownloadCSV
                                    url={getDownloadModuleRptUrl()}
                                    params={downloadModuleParams}
                                    fileName={getDownloadModuleFileName()}
                                />}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </>
        );
    }

    if (!moduleRptInput) return (<></>);
    if (moduleRptLoading) getCourseAllModuleReport();
    return (
        <Modal
            onClose={() => setShowModal(false)}
            onOpen={(e) => {getCourseAllModuleReport(); }}
            open={showModal}
            closeOnEscape={true}
            closeIcon={true}
            size='large'
        >
            <Modal.Header>Course - All Module Progress Report</Modal.Header>
            <Modal.Content scrolling>
                <Modal.Description>
                    <FilterCourseProgress/>
                    {moduleRptLoading && <Segment>
                        <Dimmer active><Loader/></Dimmer>
                    </Segment>}
                    {!moduleRptLoading && <CourseAllModuleReport/>}
                </Modal.Description>
            </Modal.Content>
        </Modal>
    );
}