import * as yup from 'yup';
import { KeyValue } from '../common/key_value';
import { CourseType } from './course';

export enum VideoPlayerType{
  JWPlayer = 1,
  Vimeo = 2,
  YouTube = 3
}

//Module will have video/audio lectures along with reference material, optional quiz,
//optional tasks (for re-entry roadmap).
//One Module can use mapped to one or more courses.
export class LearningModule {
    id: number;
    name: string;
    code: string;
    description?: string;
    shortDescription?: string;
    active: boolean;
    category?: string;
    assessmentQuestion?: string;
    mediaOneCode?:string;
    mediaOneDuration?: string;
    mediaTwoCode?:string;
    mediaTwoDuration?: string;
    isAudio: boolean;
    videoPlayerType: VideoPlayerType; //defaults to 1; 1 = JW Player, 2 = Vimeo 3 = YouTube
    videoUrl?: string; //nullable
    quizFlag: boolean;
    createdDate?: Date;
    createdBy?: string;
    updatedDate?: Date;
    updatedBy?: string;

    constructor() {
        this.id = 0;
        this.name = "";
        this.code = "";
        this.active = true;
        this.isAudio = false;
        this.videoPlayerType = 1;
        this.quizFlag = true;
    }
}

export const LearningModuleSchema = yup.object().shape({
    id: yup.number().min(0),
    name: yup.string().min(2).max(128).required()
    .test('name-no-special-chars', 'Name cannot contain special characters except for hyphens', (value) => {
      return /^[A-Za-z0-9_\- ]+$/.test(value || '');
    }),
    code: yup.string().min(2).max(24).required()
    .test('name-no-special-chars', 'Name cannot contain special characters or numbers', (value) => {
      return /^[A-Za-z0-9_]+$/.test(value || '');
    }),
    description: yup.string().min(0).max(1024),
    shortDescription: yup.string().min(0).max(255),
    active: yup.boolean(),
    category: yup.string().min(0).max(64).required(),
    assessmentQuestion: yup.string().min(0).max(512),
    mediaOneCode: yup.string().max(24),
    mediaOneDuration: yup.string().max(8),
    mediaTwoCode: yup.string().max(24),
    mediaTwoDuration: yup.string().max(8),
    isAudio: yup.boolean().default(false),
    videoPlayerType: yup.number().min(1).max(3).required(),
    videoUrl: yup.string().max(1024),
    //quizFlag: yup.boolean().required(),
  });

  export interface Choice {
    label: string;
    choiceId: number;
    selected: boolean;
    displayOrder: number;
  }


  export interface Question{
    id: number;
    q: string;
    choices: Choice[];
    c1: string;
    c1_sel: boolean;
    c2: string;
    c2_sel: boolean;
    c3: string;
    c3_sel: boolean;
    c4: string;
    c4_sel: boolean;
    c5: string;
    c5_sel: boolean;
    [key: string]: number | string | boolean | Choice[];
}

export interface Quiz {
    max_random_questions: number;
    qs: Question[]
}

export interface VideoData {
    code: string;
    smilUrl?: string;
    trackUrl?: string;
    resolutions: {
        name: string;
        resolution: string;
        videoUrl: string;
    }[];
}

export interface CourseModuleViewDto {
    courseId: number;
    courseName: string;
    courseType: CourseType;
    moduleId: number;
    name: string;
    code: string;
    description?: string;
    shortDescription?: string;
    mediaOneCode?:string;
    mediaOneDuration?: string;
    mediaTwoCode?:string;
    mediaTwoDuration?: string;
    videoPlayerType: VideoPlayerType;
    videoUrl?: string;
    quizFlag: boolean;
    category: string;
    active: boolean,
    assessmentQuestion: string;
    isAudio: boolean;
    videos: { [code: string]: VideoData };
    quiz : Quiz;
    materials: KeyValue[];
    instructorMaterials: KeyValue[];
}

export class UserModuleQuiz{
  id: number;  //primary key - auto-increment
  accountId: number; //not null foreign key account.id
  locationId: number;
  courseId: number;
  moduleId: number; //not null foreign key module.id
  userId: number; //not null foreign key user_info.id
  lastQuizMarks?: number;//nullable - decimal
  maxQuizMarks?: number; //nullable
  transcriptViewedDate?: Date;
  completedFlag?:boolean;
  completedDate?: Date;
  lastQuizAttemptedDate?: Date; //nullable
  firstModuleAccessedDate?: Date; //nullable
  lastModuleAccessedDate?: Date; //nullable

  mediaOneTotalViewTime?: number;
  mediaOneLastSeekPos?: number;
  mediaOneViewedDate?: Date;

  mediaTwoTotalViewTime?: number;
  mediaTwoLastSeekPos?: number;
  mediaTwoViewedDate?: Date;

  totalQuizAttempts?:number;
  mediaTrackingEvents?: string;

  constructor() {
      this.id = 0;
      this.accountId = 0;
      this.locationId = 0;
      this.courseId = 0;
      this.moduleId = 0;
      this.userId = 0;
      this.completedFlag = false;
  }
}


export class UerModuleTask{
  id: number;
  account_id: number;
  module_id: number;
  user_id: number;
  last_attempted_date?: Date;
  assessment_question_ans?: boolean;
  todo_list_json?: string ;

  constructor() {
      this.id = 0;
      this.account_id = 0;
      this.module_id = 0;
      this.user_id = 0;
  }
}

export interface UserCourseModuleOutputDto  {
  module: CourseModuleViewDto,
  userModuleQuiz: UserModuleQuiz
}

export const categoryListJson = [
    {
    key:"3rd Party",
    value: "3rd Party",
    text: "3rd Party"
  },
  {
    key:"Classic",
    value: "Classic",
    text: "Classic"
  },
  {
    key:"Corrections",
    value: "Corrections",
    text: "Corrections"
  },
  {
    key:"Disable",
    value: "Disable",
    text: "Disable"
  },
  {
    key:"Dual",
    value: "Dual",
    text: "Dual"
  },
  {
    key:"General",
    value: "General",
    text: "General"
  },
  {
    key:"Insights",
    value: "Insights",
    text: "Insights"
  },
  {
    key:"Interlift",
    value: "Interlift",
    text: "Interlift"
  },
  {
    key:"UK",
    value: "UK",
    text: "UK"
  },
  {
    key:"Welcome Video",
    value: "Welcome Video",
    text: "Welcome Video"
  }
];

export const videoPlayerTypeJson = [
  {
  key:"1",
  value: 1,
  text: "JW Player"
},
{
  key:"2",
  value: 2,
  text: "Vimeo"
},
{
  key:"3",
  value: 3,
  text: "YouTube"
}
];