import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

type ConfirmDeleteModalProps = {
  confirmDeleteOpen: boolean;
  setConfirmDeleteOpen: (open: boolean) => void;
  handleConfirmDelete: () => void;
};

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
  confirmDeleteOpen,
  setConfirmDeleteOpen,
  handleConfirmDelete,
}) => {
  return (
    <Modal
      open={confirmDeleteOpen}
      onClose={() => setConfirmDeleteOpen(false)}
      size="small"
    >
      <Modal.Header>Delete Slot</Modal.Header>
      <Modal.Content>
        <p>Are you sure you want to delete this slot?</p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setConfirmDeleteOpen(false)}>Cancel</Button>
        <Button
          color="red"
          onClick={() => {
            handleConfirmDelete();
            setConfirmDeleteOpen(false);
          }}
        >
          Delete
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmDeleteModal;
