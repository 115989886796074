import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Form } from 'semantic-ui-react';
import { useParams, useNavigate } from 'react-router-dom';
import { Campaign } from '../../models/campaign/campaign';
import { api } from '../../shared/axios_wrapper';
import { Result } from '../../models/common/paged_result';
import { toast } from 'react-toastify';
import TextInput from '../../app/components/text_input';
import SelectInput from '../../app/components/selected_input';
import { DocumentTitle } from '../../app/components/document_title';
import TextArea from '../../app/components/text_area';

const CampaignEdit = () => {
  const { campaignId } = useParams<{ campaignId: string }>();
  const navigate = useNavigate();
  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const campaignIdInt = parseInt(campaignId ?? '0');

  // Validation schema for Formik using Yup
  const CampaignSchema = Yup.object().shape({
    name: Yup.string().min(2).max(64).required(),
    msgPrefix: Yup.string().max(64).nullable(),
    active: Yup.boolean().required(),
    campaignPhone: Yup.string().min(10).max(15).required(),
    optInMsg: Yup.string().max(160).nullable(),
    optOutMsg: Yup.string().max(160).nullable(),
    generalMsg: Yup.string().max(160).nullable(),
  });

  const defaultCampaign: Campaign = {
    id: 0,
    name: '',
    active: true,
    campaignPhone: '',
    userCount: 0,
    msgCount: 0,
    createdDate: new Date(),
    msgPrefix: 'NBUG',
    optInMsg: 'Nudge Bug starts soon!⏱About 25 msg/month. SMS rates may apply. acceleronlearning.com/nudge-bug-terms/ Text STOP to end.',
    optOutMsg: '',
    generalMsg: 'Thanks for letting us know! Tap https://www.findhelp.org/ for local help.'
  };

  const transformCampaign = (campaign: Campaign): Campaign => {
    return {
      ...campaign,
      msgPrefix: campaign.msgPrefix ?? '',
      optInMsg: campaign.optInMsg ?? '',
      optOutMsg: campaign.optOutMsg ?? '',
      generalMsg: campaign.generalMsg ?? '',
    };
  };

  // Fetch campaign data if editing an existing campaign
  useEffect(() => {
    if (campaignIdInt > 0) {
      setLoading(true);
      api.get<Result<Campaign>>(`/campaign/${campaignIdInt}/get`)
        .then(response =>
          {
            if (response?.data?.result) {
              setCampaign(transformCampaign(response?.data?.result));
            }
          })
        .catch(() => toast.error("Unable to load campaign"))
        .finally(() => setLoading(false));
    }
  }, [campaignIdInt]);

  const handleSubmit = async (values: Campaign) => {
    setSubmitting(true);
    const url = '/campaign/save';
    api.post<Campaign>(url, values)
      .then(() => {toast.error("Campaign Saved Successfully."); navigate('/admin/campaigns');})
      .catch(() => toast.error("Unable to save campaign"))
      .finally(() => setSubmitting(false));
  };

  const CampaignForm = () => (
    <Formik
      initialValues={campaign || defaultCampaign}
      validationSchema={CampaignSchema}
      enableReinitialize
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ handleSubmit, isValid, dirty }) => (
        <Form id="campaignForm" className="ui form" onSubmit={handleSubmit} size="big">

          <Form.Group widths="equal">
            <TextInput
              name="name"
              placeholder="Campaign Name (Max. 64 characters)"
              maxLength={64}
            />
            <TextInput
              name="campaignPhone"
              placeholder="Campaign Phone (10 to 15 characters)"
              maxLength={15}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <SelectInput
              name="active"
              placeholder='Active?'
              options={[
                { key: 'yes', text: 'Yes', value: true },
                { key: 'no', text: 'No', value: false }
              ]}
            />
            <TextInput
              name="msgPrefix"
              placeholder="Msg Prefix (Max. 64 characters)"
              maxLength={15}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <TextArea placeholder="General Msg (Max. 160 characters)" name="generalMsg" rows={3} />
          </Form.Group>

          <Form.Group widths="equal">
            <TextArea placeholder="Opt-In Msg (Max. 160 characters)" name="optInMsg" rows={3}   />
          </Form.Group>

          <Form.Group widths="equal">
            <TextArea placeholder="Opt-Out Msg (Max. 160 characters)" name="optOutMsg" rows={3} />
          </Form.Group>

          <Button type="submit" primary disabled={submitting}>
            Save
          </Button>
          <Button onClick={() => navigate('/admin/campaigns')}>
            Cancel
          </Button>
        </Form>
      )}
    </Formik>
  );

  return (
    <>
    <DocumentTitle title={"Campaign Edit"} />
    <div>
      <h1>{campaignIdInt <= 0 ? 'Add Campaign' : 'Edit Campaign'}</h1>
      {!loading && <CampaignForm />}
    </div>
    </>
  );
};

export default CampaignEdit;
